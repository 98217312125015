<template>
	<div class="lineManagement">
		<jy-query ref="checkForm" :model="formInline" label-width="100px">
			<jy-query-item label="班次调整类别:" prop="leibie">
				<el-select v-model="formInline.leibie" placeholder="请选择">
					<el-option v-for="item in lboptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('checkForm')">重置</el-button>
				<!-- <jy-query-item>
                                <el-button type="primary" @click="onexport">导出</el-button>
                            </jy-query-item> -->
				<!-- <el-row>
					<el-button type="primary" @click="add">新增</el-button>
					<el-button type="primary" @click="batchdelete">批量删除</el-button>
					<el-button type="primary" @click="batchimport">导出</el-button>
				</el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" @click="add">新增</el-button>
				<el-button type="primary" @click="batchdelete">批量删除</el-button>
				<el-button type="primary" @click="batchimport">导出</el-button>
			</template>
		</jy-query>
		<jy-table
			ref="jyTable"
			:data="lists"
			tooltip-effect="dark"
			@select-all="selectallchange"
			header-cell-class-name="bg_color"
			border
			@select="selectchange"
			style="width: 100%"
		>
			<jy-table-column type="index" width="60" label="序号" align="center"> </jy-table-column>
			<jy-table-column type="selection" width="40"> </jy-table-column>
			<jy-table-column prop="a" label="班次调整类别" min-width="100"> </jy-table-column>
			<jy-table-column prop="b" label="班次调整原因" min-width="100"> </jy-table-column>
			<jy-table-column prop="beizhu" label="备注" min-width="200"> </jy-table-column>
			<jy-table-column prop="time" label="创建日期" min-width="100"> </jy-table-column>
			<jy-operate v-bind:list="operateList" width="160"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		>
		</jy-pagination>
		<add-dialog ref="dialog" @updateList="getlist"></add-dialog>
	</div>
</template>
<script>
import adddialog from "@/components/pages/admin/basicInformation/divisionsAdjustment/adddivisions";
export default {
	data() {
		return {
			formInline: {
				leibie: ""
			},
			lists: [
				{
					a: "快充王",
					b: 1,
					c: "2019-10-10",
					d: "text",
					e: "text",
					f: "text",
					beizhu: "text",
					time: "text"
				}
			],
			lboptions: [
				{
					value: "",
					label: "全部"
				},
				{
					value: "1",
					label: "停车场站"
				},
				{
					value: "2",
					label: "维修场站"
				},
				{
					value: "3",
					label: "充电场站"
				},
				{
					value: "4",
					label: "加油场站"
				}
			],
			// 批量删除
			batchdeleteList: [],
			// 分页
			pageIndex: 1,
			total: 100,
			pageSize: 10,
			//  操作
			operateList: []
		};
	},
	components: {
		"add-dialog": adddialog
	},
	created() {
		// this.getlist()
		this.setOperateList();
	},
	methods: {
		setOperateList() {
			let l = [
				{
					name: "修改",
					icon: "el-icon-edit-outline",
					fun: this.handleEdit
				},
				{
					name: "删除",
					icon: "el-icon-delete",
					fun: this.handleDelete
				}
			];
			this.operateList = l;
		},
		// cjOptionsInit() {
		//     this.$http
		//         .post('/info/dictchargingpilebrand/queryAllPileBrandInfo', {})
		//         .then(res => {
		//             this.cjOptions = res.detail
		//         })
		// },
		getlist() {},
		deleteRecord(brandIdAry) {
			this.$http.post("/info/dictchargingpilebrand/delete", brandIdAry).then(data => {
				if (data.code == "0") {
					this.$message.success(data.msg);
					this.getlist();
				} else {
					this.$message.error(data.msg);
				}
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getlist();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 导出
		onexport() {
			console.log("导出");
		},
		// 新增
		add() {
			this.$refs.dialog.init();
			console.log("新增");
		},
		// 批量删除
		batchdelete() {
			this.$confirm("是否删除该文件?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		// 批量导入
		batchimport() {},

		selectchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},

		// 删除
		handleDelete(row) {
			this.$confirm("是否删除该文件?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let params = [];
					params.push(row.brandId);
					this.deleteRecord(params);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
			//   console.log(row)
		},
		// 修改
		handleEdit(row) {
			this.$refs.dialog.init(row, "update");
			console.log("编辑");
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getlist();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getlist();
		}
	}
};
</script>
<style lang="scss" scoped="brand"></style>
<style lang="scss"></style>
